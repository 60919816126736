import Routing from './routes';

function App() {
  return (

    <div className='app'>
      <Routing />
    </div>

  );
}

export default App;
